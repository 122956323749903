<template>
  <div class="about-us">
    <div class="introduce">
      <h2>公司介绍</h2>
      <div class="intr-bottom-box"></div>
      <a-row class="intr-list">
        <a-col :span="12" :xs="24" :lg="12" class="list-l-box">
          <p>
            上海达译机电工程有限公司成立于2013年，是集研发、制造、销售、服务为一体的互联网工业信息化管理系统服务企业，公司以“we
            do better”作为企业理念，以“Freedom And
            Responsibility”为坚持发展原则。公司客户覆盖半导体制造、汽车工业、食品加工、机械制造、硬件制造、物流、仓储、制药、能源等大多数工业企业。公司立足国内智能化工业控制和管理，同时将致力于为亚太地区的客户提供智能化工厂解决方案。
          </p>
          <p>
            2018年8月，武汉达译自动化工程有限公司成立，积极创新，拥有近20项行业专利、软件著作权，先后被评定“国家高新技术企业”、“技术先进型服务企业”、“双软企业”、“科技型中小企业”
          </p>
          <p>
            2019年被湖北省软件行业协会纳入为会员单位，同年被湖北软件协会评定为“软件企业“。
          </p>
        </a-col>
        <a-col :span="12" :xs="24" :lg="12" class="animate__animated animate__fadeInRightBig list-r-box">
          <ul>
            <li>
              <div class="li-l">
                <div>
                  <p><span>6</span>家</p>
                </div>
              </div>
              <div class="li-r">
                <h3>6家分支机构</h3>
                <p>上海达译机电工程有限公司 ：工厂机电自动化工程服务</p>
                <p>陕西达译机电工程有限公司 ：工厂机电自动化工程服务</p>
                <p>武汉达译自动化工程有限公司 ：工业数字化和控制系统软件研发</p>
                <p>北京骏观数据科技有限公司 ：工业大数据及AI系统产品研发</p>
                <p>呼和浩特分公司 ：工厂数字化软硬件项目实施交付</p>
                <p>青岛达译智能化科技有限公司 ：工厂数字化产品研发</p>
              </div>
            </li>
            <li>
              <div class="li-l">
                <div>
                  <p><span>10</span>+</p>
                </div>
              </div>
              <div class="li-r">
                <h3>10+年专业经验</h3>
                <p>
                  10+年工厂自动化和数字化系统研发，实施和交付经验，客户遍布中国，日本，韩国，越南，印尼，孟加拉，多哥，伯利兹，埃塞俄比亚，加纳等十几个国家。
                </p>
              </div>
            </li>
            <li>
              <div class="li-l">
                <div>
                  <p><span>70</span>+</p>
                </div>
              </div>
              <div class="li-r">
                <h3>70+资深工程师</h3>
                <p>
                  70+长期从事工业生产领域系统软硬件开发，调试的行业专家、能力专家以及市场人员
                </p>
              </div>
            </li>
          </ul>
        </a-col>
      </a-row>
    </div>

    <!-- 企业资质 -->
    <a-row class="seniority">
      <h2>企业资质</h2>
      <p>
        公司成于2013年，<span>注册1000万元</span>，注重解决方案设计、方案实施和项目管理。
      </p>
      <div>
        <!-- <img class="img" src="../../assets/image/825.png" alt="">
        <img class="img" src="../../assets/image/824.png" alt="">
        <img class="img" src="../../assets/image/823.png" alt=""> -->
        <img src="../../assets/image/1647484301.png" alt="" />
      </div>
    </a-row>

    <!-- 合作伙伴 -->
    <div class="cooperative-partner">
      <h2 align="center">合作伙伴</h2>
      <p align="center">
        与您一起见证推荐科技的发展进程，为您提供技术解惑，掌握开发与运营推广的重点要点与注意事项
      </p>
      <ul class="coop-ul">
        <li :span="3" v-for="item in partnerList" :key="item.id">
          <img :src="item.partIcon" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partnerList: [
        {
          id: 1,
          partIcon: require("../../assets/image/159.png"),
        },
        {
          id: 2,
          partIcon: require("../../assets/image/160.png"),
        },
        {
          id: 3,
          partIcon: require("../../assets/image/161.png"),
        },
        {
          id: 4,
          partIcon: require("../../assets/image/162.png"),
        },
        {
          id: 5,
          partIcon: require("../../assets/image/163.png"),
        },
        {
          id: 6,
          partIcon: require("../../assets/image/164.png"),
        },
        {
          id: 7,
          partIcon: require("../../assets/image/165.png"),
        },
        {
          id: 8,
          partIcon: require("../../assets/image/166.png"),
        },
        {
          id: 9,
          partIcon: require("../../assets/image/167.png"),
        },
        {
          id: 10,
          partIcon: require("../../assets/image/168.png"),
        },
        {
          id: 11,
          partIcon: require("../../assets/image/169.png"),
        },
        {
          id: 12,
          partIcon: require("../../assets/image/170.png"),
        },
        {
          id: 13,
          partIcon: require("../../assets/image/171.png"),
        },
        {
          id: 14,
          partIcon: require("../../assets/image/172.png"),
        },
        {
          id: 15,
          partIcon: require("../../assets/image/173.png"),
        },
        {
          id: 16,
          partIcon: require("../../assets/image/174.png"),
        },
        {
          id: 17,
          partIcon: require("../../assets/image/175.png"),
        },
        {
          id: 18,
          partIcon: require("../../assets/image/176.png"),
        },
      ],
    };
  },
};
</script>

<style lang="less">
.about-us {
  .introduce {
    width: 1440px;
    margin: 0 auto;
    color: #333333;
    font-size: 20px;
    margin-bottom: 140px;

    h2 {
      color: #333333;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    .intr-bottom-box {
      width: 72px;
      height: 16px;
      background: linear-gradient(to right, #ff812e, #ffffff);
      margin-bottom: 60px;
    }

    .intr-list {
      .list-l-box {
        padding: 50px;
      }

      .list-r-box {
        color: #ffffff;
        box-shadow: 0px 10px 30px rgba(23, 35, 55, 0.1);
        border-radius: 10px;
        background: url("../../assets/image/组 741.png");
        padding: 50px 54px;

        ul {
          width: 100%;

          li {
            display: flex;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;

            .li-l {
              div {
                width: 104px;
                height: 104px;
                border-radius: 50%;
                background: #ffffff;
                text-align: center;
                color: #ff812e;
                line-height: 104px;

                span {
                  font-size: 50px;
                  font-weight: 500;
                }
              }
            }

            .li-r {
              margin-left: 30px;

              h3 {
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }

  .seniority {
    padding: 105px 260px;
    width: 100%;
    height: 787px;
    background-color: aquamarine;
    background: url("../../assets/image/103.png") no-repeat;
    text-align: center;

    h2 {
      color: #0e0c1b;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    p {
      font-size: 20px;
      font-family: SourceHanSansCN-Regular;
      color: #777777;

      span {
        color: #ff6e29;
      }
    }

    div {
      display: flex;
      justify-content: space-between;

      img {
        width: 1440px;
        height: 520px;
      }
    }
  }

  .cooperative-partner {
    width: 1440px;
    margin: 0 auto;
    margin-top: 140px;

    h2 {
      color: #0e0c1b;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    p {
      font-size: 20px;
      font-family: SourceHanSansCN-Regular;
      color: #777777;
      margin-bottom: 100px;
    }

    .coop-ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;

      li {
        width: 200px;
        height: 200px;
        border-radius: 200px;
        // margin-right: 60px;
        margin: 20px;
        text-align: center;
        line-height: 200px;
        box-shadow: 2px 2px 15px #eeeeee;
        transition: 0.6s all ease;

        img {
          width: 182px;
          height: 182px;
        }

        &:hover {
          transform: scale(1.3);
          box-shadow: 8px 8px 25px #ffb9ae;
        }
      }
    }
  }
}

@media all and (max-width: 700px) {
  .about-us {
    .introduce {
      width: 1440px;
      margin: 0 auto;
      color: #333333;
      font-size: 20px;
      margin-bottom: 140px;

      h2 {
        color: #333333;
        font-size: 80px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }

      .intr-bottom-box {
        width: 148px;
        height: 16px;
        background: linear-gradient(to right, #ff812e, #ffffff);
        margin-bottom: 60px;
      }

      .intr-list {
        .list-l-box {
          padding: 50px;
          p {
            font-size: 48px;
          }
        }

        .list-r-box {
          color: #ffffff;
          box-shadow: 0px 10px 30px rgba(23, 35, 55, 0.1);
          border-radius: 20px;
          background: url("../../assets/image/组 741.png");
          padding: 50px 45px;

          ul {
            width: 100%;

            li {
              display: flex;
              font-size: 38px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              margin-bottom: 50px;

              .li-l {
                div {
                  width: 208px;
                  height: 208px;
                  border-radius: 50%;
                  background: #ffffff;
                  text-align: center;
                  color: #ff812e;
                  line-height: 208px;
                  p {
                    font-size: 50px;
                    span {
                      font-size: 100px;
                      font-weight: 500;
                    }
                  }
                }
              }

              .li-r {
                margin-left: 25px;
                p {
                  font-size: 46px;
                }
                h3 {
                  font-size: 48px;
                  font-family: Source Han Sans CN;
                  font-weight: 800;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }

    .seniority {
      padding: 105px 0px;
      width: 100%;
      height: 1200px;
      background-color: aquamarine;
      background: url("../../assets/image/103.png") no-repeat;
      text-align: center;
      border-radius: 20px;
      h2 {
        color: #0e0c1b;
        font-size: 80px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }

      p {
        font-size: 40px;
        font-family: SourceHanSansCN-Regular;
        color: #777777;

        span {
          color: #ff6e29;
        }
      }

      div {
        display: flex;
        justify-content: space-between;

        img {
          width: 100%;
          height: 80%;
        }
      }
    }

    .cooperative-partner {
      width: 1440px;
      margin: 0 auto;
      margin-top: 140px;

      h2 {
        color: #0e0c1b;
        font-size: 80px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }

      p {
        font-size: 40px;
        font-family: SourceHanSansCN-Regular;
        color: #777777;
        margin-bottom: 100px;
      }

      .coop-ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        li {
          width: 400px;
          height: 400px;
          border-radius: 400px;
          // margin-right: 60px;
          margin: 20px;
          text-align: center;
          line-height: 400px;
          box-shadow: 4px 4px 30px #eeeeee;
          transition: 0.6s all ease;

          img {
            width: 182px * 2;
            height: 182px * 2;
          }

          &:hover {
            transform: scale(1.3);
            box-shadow: 8px 8px 25px #ffb9ae;
          }
        }
      }
    }
  }
}</style>
